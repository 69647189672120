<!--
 * @Author: your name
 * @Date: 2021-08-16 15:09:42
 * @LastEditTime: 2021-09-09 10:29:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\common\loading\index.vue
-->
<template>
  <div class="loading">加载中</div>
</template>

<script>
import getQuery from './getQuery.js';
import { mapGetters } from 'vuex';
import { getUserInfo } from '@/api/main/system/loginUser';
import store from 'utils/store';
import { getMenuList } from '@/api/main/system/menuMana';
export default {
  created() {
    this.getTokenAndAppInfo();
  },
  computed: {
    ...mapGetters(['appInfo'])
  },
  data() {
    return {
      haveMenu: []
    };
  },
  methods: {
    //获取用户信息
    getUserInfo() {
      getUserInfo().then(res => {
        this.$store.commit('SET_USER_INFO', res.data);
        if (res.data.adminType == 2) {
          //  是 子系统超级管理员
          this.$router.replace('/admin');
        } else {
          this.haveMenu = res.data?.menuList;
          store.setSession('permissions', this.haveMenu);
          getMenuList().then(data => {
            const allMenu = data.data[0].children;
            this.isHaveMenu(allMenu);
            this.formatArr(allMenu);
            store.setSession(
              'allMenu',
              allMenu.filter(item => item.show)
            );
            this.$router.replace('/projectMana');
          });
        }
      });
    },
    // 获取 url 参数
    getUrlParams(name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(
            /\+/g,
            '%20'
          )
        ) || null
      );
    },
    // 获取 url携带 token用于 获取用户信息； appInfo 用于封装请求头 AppCode
    getTokenAndAppInfo() {
      // const appInfo = JSON.parse(this.getUrlParams('appInfo'));
      // const token = this.getUrlParams('token');
      // const orgId = this.getUrlParams('orgId');

      const { appInfo, token, orgId } = getQuery(location.href);
      this.$store.commit('SET_TOKEN', token);
      this.$store.commit('SET_APPINFO', appInfo);
      store.setSession('orgId', orgId);

      this.getUserInfo();
    },
    isHaveMenu(list) {
      list.forEach(item => {
        this.haveMenu.forEach(have => {
          if (have.code === item.code) {
            item.show = true;
          }
        });
        this.isHaveMenu(item.children);
      });
    },
    formatArr(arr) {
      arr.forEach(item => {
        if (item.children?.length) {
          this.formatArr(item.children);
          item.show = item.children.some(subItem => subItem.show === true);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped></style>
